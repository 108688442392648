
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { createKey, deleteKey, getKeys, viewKey } from 'Store/v2/PlacementApiKeysManagement';
import TradeAPIKey from 'Entities/cexAccountManagement/TradeAPIKey';
import theme from 'Theme';
import Icon from 'UI/Icon.vue';
import Guid from 'UI/Guid.vue';
import TextInput from 'Control/TextInput.vue';
import Button from 'Control/Button.vue';
import CreateTradeAPIKeyRequest from 'Entities/cexAccountManagement/CreateTradeAPIKeyRequest';
import NoData from 'UI/NoData.vue';
import DeleteAPIKeyRequest from 'Entities/cexAccountManagement/DeleteAPIKeyRequest';
import ApiError from 'Entities/ApiError';
import GetTradeAPIKeySecretsRequest from 'Entities/cexAccountManagement/GetTradeAPIKeySecretsRequest';
import AssetsBlockchainsDropdown from 'Control/AssetsBlockchainsDropdown.vue';
import MfaDisabled from 'Common/MfaDisabled.vue';

type Data = {
    theme: typeof theme;
    newKeyName: string;
    activePlacementIndex: number;
    ipAddresses: string;
    ipAddressesRegExp: RegExp;
    errorsSet: Set<'label' | 'ips'>;
};

type Methods = {
    getPlacementNameByTag: (data: string) => string;
    getDate: (data: string | undefined) => string;
    createApiKey: () => void;
    validateApiKey: () => boolean;
    onNewKeyNameChange: (data: string) => void;
    onIpsChange: (data: string) => void;
    deleteKey: (data: TradeAPIKey) => void;
    viewKey: (data: TradeAPIKey) => void;
};

type Computed = {
    activeAccountId: string;
    keys: TradeAPIKey[];
    placementsList: string[];
    isActiveAccountOwned: boolean;
    isMFADisabled: boolean;
};

export default Vue.extend<Data, Methods, Computed>({
    components: {
        MfaDisabled,
        AssetsBlockchainsDropdown,
        NoData,
        Button,
        TextInput,
        Icon,
        Guid,
    },
    data() {
        return {
            theme,
            newKeyName: '',
            activePlacementIndex: 0,
            ipAddresses: '',
            ipAddressesRegExp: /^(25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)){3}(, (25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)){3})*$/,
            errorsSet: new Set(),
        };
    },
    computed: {
        ...mapGetters({
            activeAccountId: 'Accounts/activeAccountID',
            isMFADisabled: 'Auth/isMFADisabled',
        }),
        isActiveAccountOwned() {
            if (!this.activeAccountId) {
                return true;
            }

            return this.$store.state.Accounts.accounts.find(({ id }) => id === this.activeAccountId)?.roleName === 'owner';
        },
        keys() {
            return this.$store.state.PlacementApiKeysManagement.keys;
        },
        placementsList() {
            const typesOrder = ['crypto-spot', 'crypto-futures'];
            const placementsArray = [...this.$store.state.Placements.placements];

            return placementsArray
                .sort(({ type: a }, { type: b }) => {
                    return typesOrder.indexOf(a) - typesOrder.indexOf(b);
                })
                .reduce((accum, current) => {
                    if ((current.type === 'crypto-spot' || current.type === 'crypto-futures') && !current.disabled) {
                        accum.push(current.name);
                    }
                    return accum;
                }, []);
        },
    },
    methods: {
        getPlacementNameByTag(tag) {
            const map: Map<string, string> = this.$store.state.Placements.placementNamesToPlacementTags;
            return Array.from(map.keys()).find((name) => map.get(name) === tag) ?? '...';
        },
        getDate(dateStr) {
            return Intl.DateTimeFormat('en-EN', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
                hour12: false,
                hour: 'numeric',
                minute: 'numeric',
            }).format(new Date(dateStr ?? 0));
        },
        async createApiKey() {
            const isValid = this.validateApiKey();
            if (isValid) {
                try {
                    await this.$store.dispatch(createKey(new CreateTradeAPIKeyRequest({
                        accountId: this.activeAccountId,
                        ips: this.ipAddresses.split(', ').join(','),
                        label: this.newKeyName,
                        placementTag: this.$store.state.Placements.placementNamesToPlacementTags
                            .get(this.placementsList[this.activePlacementIndex].toUpperCase()),
                    })));
                    this.onNewKeyNameChange('');
                    this.onIpsChange('');
                } catch {
                    // api error
                }
            }
        },
        validateApiKey() {
            const selectedPlacementTag = this.$store.state.Placements.placementNamesToPlacementTags
                .get(this.placementsList[this.activePlacementIndex].toUpperCase());
            const selectedPlacementKey = this.keys.find(({ placementTags }) => (placementTags ?? []).indexOf(selectedPlacementTag) !== -1);

            if (selectedPlacementKey !== undefined) {
                this.$store.dispatch('Notificator/showErrorNotification', `You already have ${selectedPlacementKey.status} key on ${this.placementsList[this.activePlacementIndex]}`);
                return false;
            }

            const tempErrorsSet = new Set<'label' | 'ips'>();

            this.newKeyName = this.newKeyName.trim();
            this.ipAddresses = this.ipAddresses.trim();
            if (this.newKeyName.length < 3 || this.newKeyName.length > 20) {
                tempErrorsSet.add('label');
            }

            const isIpsValid = this.ipAddressesRegExp.test(this.ipAddresses);
            if (!isIpsValid) {
                tempErrorsSet.add('ips');
            }

            this.errorsSet = tempErrorsSet;
            return this.errorsSet.size === 0;
        },
        onNewKeyNameChange(value) {
            this.newKeyName = value;
            if (this.errorsSet.has('label')) {
                this.errorsSet.delete('label');
            }
        },
        onIpsChange(value) {
            this.ipAddresses = value;
            if (this.errorsSet.has('ips')) {
                this.errorsSet.delete('ips');
            }
        },
        async deleteKey(key) {
            if (!key.key || !key.placementTags) {
                await this.$store.dispatch('Notificator/showErrorNotification', 'You cannot delete this key');
                return;
            }

            try {
                await this.$store.dispatch(deleteKey({
                    requestPayload: new DeleteAPIKeyRequest({
                        key: key.key,
                        placementTag: key.placementTags[0],
                    }),
                    accountId: this.activeAccountId,
                }));
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong');
                }
            }
        },
        async viewKey(key) {
            if (!key.key || !key.placementTags) {
                await this.$store.dispatch('Notificator/showErrorNotification', 'You cannot view this key');
                return;
            }

            await this.$store.dispatch(viewKey(new GetTradeAPIKeySecretsRequest({
                key: key.key,
                placementTag: key.placementTags[0],
            })));
        },
    },
    async mounted() {
        if (this.activeAccountId) {
            await this.$store.dispatch(getKeys(this.activeAccountId));
        }
    },
    watch: {
        async activeAccountId(val) {
            await this.$store.dispatch(getKeys(val));
        },
    },
});
